import React from 'react';
import Page from '../components/Page';

function Contact() {
  return (
    <Page title="Contact" slug="/contact">
      <p>Please don't talk to me.</p>
    </Page>
  );
}

export default Contact;
